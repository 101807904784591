import {useContext, useEffect, useState} from "react";
import {Link, Outlet, useLocation} from "react-router-dom";
import {TsAdminContext} from "../context/ts-admin";
import {Col, Nav, Row} from "react-bootstrap";
import {Index} from "../components/org-info";
import {ENVIRONMENT_TYPE_PRODUCT} from "../common";

export function LayoutUser(props) {
    const [menu, setMenu] = useState<any>();
    const [currentTab, setCurrentTab] = useState(useLocation().pathname);
    const [orgInfo, setOrgInfo, userInfo] = useContext(TsAdminContext);
    useEffect(() => {
        const currentMenu : any = [
            {
                id: 1,
                name: "TeamSpirit接続情報",
                path: ["/ts-connect", "/"],
            },
            {
                id: 2,
                name: "打刻連携アドオン（ICカード）",
                path: ["/pittouch-settings"],
            },

            {
                id: 4,
                name: "ICカード端末設定",
                path: ["/provider-settings"],
            },
            {
                id: 5,
                name: "QRコード打刻設定",
                path: ["/qr-settings"],
            },
            {
                id: 6,
                name: "ログ確認",
                path: ["/log-reports"],
            },
            // {
            //     id: 7,
            //     name: "打刻ログ（過去分）",
            //     path: ["/attendance-logs"],
            // },
            {
                id: 8,
                name: "打刻ログ",
                path: ["/attendance-logs-v2"],
            },
        ];
        if(orgInfo && orgInfo?.currentOrg?.ConnectType == 2) {
            currentMenu.push({
                id: 3,
                name: "TeamSpirit EX設定",
                path: ["/pittouch-ex"],
            });
        }
        const orderMenu = currentMenu.sort((a,b ) => a.id - b.id);
        setMenu(orderMenu);
    },[orgInfo])
    const getClassEnv = () => {
        if(localStorage.getItem('environment-type')) {
            const envType = localStorage.getItem('environment-type') || ENVIRONMENT_TYPE_PRODUCT;
            return envType === ENVIRONMENT_TYPE_PRODUCT ? '' : 'env-sandbox-body'
        } else {
            if(orgInfo) {
                return orgInfo?.currentOrg?.EnvironmentType === ENVIRONMENT_TYPE_PRODUCT ? '' : 'env-sandbox-body'
            }
        }

    }
    return (
        <div>
            <Row className={`${getClassEnv()} org-info-content`}>
                <Index orgInfo={orgInfo} isLoading={props.isLoading}/>
            </Row>
            <Row className={userInfo?.isSuperAdmin 
                ? "d-flex admin-view-main-content" 
                : "d-flex user-main-content"}>
                <Col lg="2" md="3" xs="4" className="p-0 menu-content">
                    <Nav className="flex-column">
                        {orgInfo && menu
                             && menu.map((item:any) => (
                            <div key={item.id}
                                className={item.path.includes(currentTab) ? "d-flex nav-item nav-item__selected" : "d-flex nav-item"}>
                                <div className="nav-pre"></div>
                                <Nav.Link
                                    as={Link}
                                    to={item.path[0]}
                                    className="nav-suf"
                                    disabled={item.path.includes(currentTab) ? true : false}
                                    onClick={() => setCurrentTab(item.path[0])}>
                                    {item.name}
                                </Nav.Link>
                            </div>
                        ))}
                    </Nav>
                </Col>
                <Col lg="10" md="9" xs="8" className={userInfo?.isSuperAdmin 
                ? `settings-content scroll-content admin-view-scroll-content ${getClassEnv()}`
                : `settings-content scroll-content user-scroll-content ${getClassEnv()}`}>
                    <Outlet/>
                </Col>
            </Row>
        </div>
    );
}