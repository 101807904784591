import * as React from "react";
import {useEffect, useState} from "react";
import {Route, Routes, BrowserRouter as Route1} from "react-router-dom";
import {AdminRole, AuthProvider, OrgType, RequireAuth} from "./provider";
import {Layout} from "./layouts/default";
import {LoginPage} from "./components/auth/login";
import {LogReportPage} from "./components/log-report";
import {ProviderSettingPage} from "./components/provider-setting";
import {TsAdminContext} from "./context/ts-admin";
import {TsConnectPage} from "./components/ts-connect";
import {CallbackLoginSfPage} from "./components/auth/callback-login-sf";
import {TsSettingPage} from "./components/ts-setting";
import {AdminConsolePage} from "./components/admin-console";
import {CreateOrganizationsPage} from "./components/admin-console/create-org";
import {Stations} from "./components/admin-console/stations";
import {UnknownStations} from "./components/admin-console/unknow-stations";
import {LogAdmin} from "./components/admin-console/log-admin";
import {EmailList} from "./components/admin-console/email-list";
import {PittouchExSetting} from "./components/pittouch-ex-settting";
import {QrSettingPage} from "./components/qr-setting";
import {AttendanceLogPage} from "./components/attendance-log/index";
import {AttendanceLogPageV2} from "./components/attendance-log/index-new";

export default function App() {
    const [orgInfo, setOrgInfo] = useState(null);
    const [userInfo, setUserInfo] = useState<any>(null);
    useEffect(() => {
        if (localStorage.getItem('user-info')) {
            setUserInfo(JSON.parse(localStorage.getItem('user-info') || ''))
        }
    }, [localStorage.getItem('user-info')])
    return (
        <AuthProvider>
            <TsAdminContext.Provider value={[orgInfo, setOrgInfo, userInfo]}>
                <Routes>
                    <Route path=":role/login" element={<LoginPage/>}/>
                    <Route path="/callback-sf" element={<CallbackLoginSfPage/>}/>
                    <Route element={<Layout/>}>
                        <Route
                            path="/"
                            element={
                                <RequireAuth>
                                    <TsConnectPage/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/ts-connect"
                            element={
                                <RequireAuth>
                                    <TsConnectPage/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/pittouch-settings"
                            element={
                                <RequireAuth>
                                    <TsSettingPage/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/provider-settings"
                            element={
                                <RequireAuth>
                                    <ProviderSettingPage/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/log-reports"
                            element={
                                <RequireAuth>
                                    <LogReportPage/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/attendance-logs"
                            element={
                                <RequireAuth>
                                    <AttendanceLogPage/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/attendance-logs-v2"
                            element={
                                <RequireAuth>
                                    <AttendanceLogPageV2/>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/pittouch-ex"
                            element={
                                <RequireAuth>
                                    <PittouchExSetting />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/qr-settings"
                            element={
                                <RequireAuth>
                                    <OrgType>
                                        <QrSettingPage/>
                                    </OrgType>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/admin-console"
                            element={
                                <RequireAuth>
                                    <AdminConsolePage/>
                                </RequireAuth>
                            }/>
                        <Route
                            path="/admin/stations"
                            element={
                                <RequireAuth>
                                    <AdminRole>
                                        <Stations />
                                    </AdminRole>
                                </RequireAuth>
                            }/>
                        <Route
                            path="/admin/unknown-stations"
                            element={
                                <RequireAuth>
                                    <AdminRole>
                                        <UnknownStations />
                                    </AdminRole>
                                </RequireAuth>
                            }/>
                        <Route
                            path="/admin/create-org"
                            element={
                                <RequireAuth>
                                    <AdminRole>
                                        <CreateOrganizationsPage />
                                    </AdminRole>
                                </RequireAuth>
                            }/>

                        <Route
                            path="/admin/logs"
                            element={
                                <RequireAuth>
                                    <AdminRole>
                                        <LogAdmin />
                                    </AdminRole>
                                </RequireAuth>
                            }/>
                        <Route
                            path="/admin/email-list"
                            element={
                                <RequireAuth>
                                    <AdminRole>
                                        <EmailList />
                                    </AdminRole>
                                </RequireAuth>
                            }/>
                        <Route
                            path="/admin/create-org/:accountId"
                            element={
                                <RequireAuth>
                                    <AdminRole>
                                        <CreateOrganizationsPage />
                                    </AdminRole>
                                </RequireAuth>
                            }/>
                    </Route>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </TsAdminContext.Provider>
        </AuthProvider>
    );
}

const NotFound = () => (
    <div className="not-found">
        <div className="d-flex align-items-center justify-content-center vh-100 bg-primary">
            <h1 className="display-1 fw-bold text-white">404</h1>
        </div>
    </div>
);

